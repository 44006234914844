<template>
  <div id="app">
    <MyHeader></MyHeader>
    <router-view />
    <MyFooter></MyFooter>
  </div>
</template>
<script>
import MyHeader from "@/components/header.vue";
import MyFooter from "@/components/footer.vue";
export default {
  components: {
    MyHeader,
    MyFooter,
  },
};
</script>
<style>
body {
  margin: 0;
}
</style>
