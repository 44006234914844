<template>
  <div class="all">
    <div class="isTop">
      <div class="isLeft">
        <div class="item1">
          <ul>
            <li>首页</li>
            <li>产品与服务</li>
            <li>题库练习</li>
            <li>课程学习</li>
          </ul>
        </div>
        <div class="item2">
          <ul>
            <li style="margin-bottom: 24px">关于我们</li>
            <li>公司介绍</li>
            <li>主要产品</li>
            <li>合作渠道</li>
            <li>功能优势</li>
            <li>联系我们</li>
          </ul>
        </div>
        <div class="item3">
          <ul>
            <li style="margin-bottom: 38px">联系我们</li>
            <li style="color: #ccc; margin-bottom: 18px">
              <div style="display: flex">
                <img
                  style="width: 20px; height: 20px"
                  src="@/assets/footer/dh.png"
                  alt=""
                />
                <span style="margin-left: 8px">
                  0532-58653556<br />
                  (服务时间：8:30-5:30 周一至周六）</span
                >
              </div>
            </li>
            <li style="color: #ccc; margin-bottom: 18px">
              <div style="display: flex">
                <img
                  style="width: 21px; height: 19px"
                  src="@/assets/footer/lingdang.png"
                  alt=""
                />
                <p style="display: inline-block; margin: 0">
                  <span style="margin-left: 8px; margin-top: 8px">
                    taijie@shejiyuan.com<br />
                  </span>
                  <span
                    style="
                      display: inline-block;
                      padding-left: 8px;
                      margin-top: 8px;
                    "
                    >中国山东青岛中央商务区敦化路328号诺德广场A座T层</span
                  >
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="isRight">
        <p>关注/体验</p>
        <img
          style="width: 100px; height: 100px; border-radius: 9px"
          src="@/assets/footer/rcode.jpg"
          alt=""
        />
      </div>
    </div>
    <div class="isBottom">
      <p class="text1">
        Copyright 2024 青岛泰捷网络科技有限公司 All Rights Reserved 备案号:
        <span
          @click="gogogo('https://beian.miit.gov.cn/')"
          style="cursor: pointer"
          >鲁ICP备14014132号-5</span
        >
        <img
          style="
            width: 20px;
            height: 20px;
            margin-left: 10px;
            display: inline-block;
          "
          src="@/assets/footer/gwab.png"
          alt=""
        />
        鲁公网安备
        <span
          @click="
            gogogo(
              'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=37020302372090'
            )
          "
          style="cursor: pointer"
          >37020302372090号</span
        >
        <img
          @click="
            gogogo(
              'http://name:80/ccnt/sczr/service/business/emark/toDetail/87182975268b42749102dd7024c364c5'
            )
          "
          style="
            transform: translate(0px, 3px);
            width: 20px;
            height: 20px;
            margin-left: 10px;
            display: inline-block;
          "
          src="@/assets/footer/wh.png"
          alt=""
        />
        网络文化经营单位许可证号：
        <span style="cursor: pointer;margin-right:20px;">鲁网文【2023】3763-116号 </span>
        电信业务经营许可证： <span style="cursor: pointer">鲁B2-20231359</span>
      </p>
      <p class="text2">
        声明：依据国家《互联网管理规定》，本网站禁止发布任何违反中华人民共和国法律、法规的内容。
      </p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    gogogo(url) {
      let el = document.createElement("a");
      el.target = "_blank";
      el.href = url;
      document.body.appendChild(el);
      el.click();
      document.body.removeChild(el);
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  list-style: none;
}
ul {
  padding: 0;
}
.all {
  height: 437px;
  width: 100%;
  min-width: 1320px;
  background: #111c2f;
  .isTop {
    width: 1320px;
    margin: auto;
    // background: pink;
    height: 330px;
    display: flex;
    justify-content: space-between;

    .isLeft {
      padding-top: 59px;
      width: 622px;
      height: 100%;
      // background: skyblue;
      color: #fff;
      display: flex;
      justify-content: space-between;

      .item1 {
        font-weight: 500;
        width: 90px;
        height: 100%;
        font-size: 18px;
        // background: #fff;
        li {
          margin-bottom: 24px;
        }
      }
      .item2 {
        font-weight: 400;
        width: 72px;
        height: 100%;
        // background: #000;
        li {
          margin-bottom: 15px;
        }
      }
      .item3 {
        width: 292px;
        height: 100%;
        // background: #fff;
      }
    }
    .isRight {
      padding-top: 59px;
      width: 108px;
      height: 100%;
      // background: #414b6d;
      p {
        margin: 0;
        color: #fff;
        font-size: 24px;
        margin-bottom: 37px;
      }
    }
  }
  .isBottom {
    border-top: 1px solid #414b6d;
    font-size: 14px;

    color: #9698a2;
    p {
      margin: 0;
      text-align: center;
    }
    .text1 {
      margin-top: 30px;
    }
    .text2 {
      margin-top: 4px;
    }
  }
}
</style>