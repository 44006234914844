<template>
  <div
    class="MyHeader"
    :style="
      show
        ? { position: 'static', position: 'relative' }
        : { position: 'fixed' }
    "
    ref="isHead"
  >
    <div class="content">
      <img
        style="height: 43px; width: auto"
        src="@/assets/head/logo1.png"
        alt=""
      />
      <div class="right">
        <span @click="gogogo('/home')">首页</span>
        <span
          @mouseleave="leave"
          @mouseenter="enter"
          @click="gogogo('/produceANDservice')"
          >产品与服务 <i class="el-icon-arrow-down"></i>
        </span>
        <span @click="gogogo('/about')">关于我们</span>
        <span @click="gogogo('/news')">资讯动态</span>
      </div>
    </div>
    <transition name="el-zoom-in-top">
      <div
        @mouseleave="leave"
        @mouseenter="enter"
        v-show="isShow"
        class="isBody"
      >
        <div class="content">
          <div
            @click="gogogo2(item.router)"
            class="item"
            v-for="(item, index) of kmLits"
            :key="index"
          >
            <div
              style="margin-left: 10px"
              :class="'iconfont ' + item.icon"
            ></div>
            <div style="flex: 1">
              <p class="text1">{{ item.name }}</p>
              <p class="text2">{{ item.text }}</p>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      now: "",
      isShow: false,
      time: "",
      kmLits: [
        {
          icon: "icon-a-bianzu22",
          name: "特种作业人员培训学习系统",
          text: "（应急管理局（原安监局））",
          router: "/introduce/tzzy",
        },
        {
          icon: "icon-tezhongshebei",
          name: "特种设备作业人员培训系统",
          text: "（市场监管局(原质监局)）",
          router: "/introduce/tzsb",
        },

        {
          icon: "icon-baoguo",
          name: "一般工贸行业管理人员培训系统",
          text: "（应急管理局（原安监局））",
          router: "/introduce/ybgm",
        },
        {
          icon: "icon-yiyuanjiuzhen",
          name: "职业卫生安全管理培训系统",
          text: "（卫健委）",
          router: "/introduce/zyws",
        },

        {
          icon: "icon-a-bianzu11",
          name: "企业安全生产培训学习系统",
          text: "（生产经营类企业/园区）",
          router: "/introduce/qyaq",
        },

        {
          icon: "icon-yuzhijineng",
          name: "职业技能等级认定培训系统",
          text: "（人社局）",
          router: "/introduce/zyjn",
        },

        {
          icon: "icon-yuanqu",
          name: "建筑类从业人员培训系统",
          text: "（建设厅）",
          router: "/introduce/jzl",
        },

        {
          icon: "icon-tz-jiaotongyunshu",
          name: "交通运输行业在线培训系统",
          text: "（运管局）",
          router: "/introduce/jtys",
        },

        {
          icon: "icon-a-bianzu7",
          name: "高危行业管理人员培训系统",
          text: "（应急管理局（原安监局））",
          router: "/introduce/tzzy",
        },
        {
          icon: "icon-a-bianzu63",
          name: "企业三级安全教育专项培训系统",
          text: "（生产经营类企业/园区）",
          router: "/introduce/qyaq2",
        },
        {
          icon: "icon-a-bianzu64",
          name: "企业安全生产双体系建设管理系统",
          text: "（生产经营类企业/园区）",
          router: "/introduce/qyaq3",
        },
        {
          icon: "icon-a-bianzu62",
          name: "企业工伤预防能力提升专项培训系统",
          text: "（生产经营类企业/园区）",
          router: "/introduce/qyaq4",
        },
      ],
    };
  },
  computed: {
    show() {
      return this.now.indexOf("introduce") != -1 ||
        this.now.indexOf("Detail") != -1
        ? true
        : false;
    },
  },
  methods: {
    gogogo(url) {
      this.$router.push(url);
    },
    gogogo2(url) {
      this.isShow = false;
      this.$router.push(url);
    },
    leave() {
      this.time = setTimeout(() => {
        this.isShow = false;
      }, 300);
    },
    enter() {
      clearTimeout(this.time);
      this.isShow = true;
    },
    handleScroll() {
      if (document.documentElement.scrollTop >= 150) {
        this.$refs.isHead.style.background =
          "linear-gradient(90deg,rgba(248, 249, 252, 1) 0%,rgba(240, 243, 250, 1) 100%)";
      } else {
        this.$refs.isHead.style.background =
          "linear-gradient(90deg,rgba(248, 249, 252, 0.19) 0%,rgba(240, 243, 250, 0.19) 100%)";
      }
    },
  },
  watch: {
    $route: {
      handler(val) {
        this.now = val.path;
      },
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
};
</script>

<style lang="scss" scoped>
p {
  margin: 0;
}
.MyHeader {
  border-bottom: 1px solid #eeeeee;
  position: relative;
  box-sizing: border-box;
  padding: 0 auto;
  display: flex;
  align-items: center;
  z-index: 999;
  height: 100px;
  width: 100%;
  min-width: 1300px;
  background: linear-gradient(
    90deg,
    rgba(248, 249, 252, 0.19) 0%,
    rgba(240, 243, 250, 0.19) 100%
  );
  //   opacity: 0.19;
  position: fixed;
  top: 0;
  .content {
    width: 1320px;
    height: 100px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .right {
      width: 420px;
      display: flex;
      justify-content: space-between;
      span {
        display: inline-block;
        height: 100px;
        cursor: pointer;
        line-height: 100px;
        &:hover {
          color: rgba(10, 84, 220, 1);
        }
      }
    }
  }
  .isBody {
    box-shadow: 0px 6px 20px 0px rgba(138, 152, 181, 0.2);
    position: absolute;
    bottom: -522px;
    left: 0;
    height: 522px;
    width: 100%;
    min-width: 1320px;
    background: url("@/assets/head/BG.png");
    .content {
      width: 1320px;
      height: 440px;
      margin-top: 48px;
      display: flex;
      justify-content: space-between;
      align-content: space-between;
      flex-wrap: wrap;
      .item {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        &:hover {
          color: #0a54dc;
        }
        background: url("@/assets/head/itemBg.png");
        // background: black;
        width: 310px;
        height: 125px;
        .iconfont {
          font-size: 36px;
          margin-right: 12px;
          color: #000;
        }
        p {
          text-align: center;
        }
        .text1 {
          font-size: 16px;
          font-weight: 400;

          margin-bottom: 6px;
        }
        .text2 {
          font-size: 12px;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
}
</style>