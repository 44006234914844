import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",

    component: () => import("@/views/home.vue"),
  },
  {
    path: "/produceANDservice",
    name: "produceANDservice",

    component: () => import("@/views/produceANDservice.vue"),
  },
  {
    path: "/about",
    name: "about",

    component: () => import("@/views/aboutNew.vue"),
  },
  {
    path: "/lessonList",
    name: "lessonList",

    component: () => import("@/views/lessonList.vue"),
  },
  {
    path: "/lessonDetail",
    name: "lessonDetail",

    component: () => import("@/views/lessonDetail.vue"),
  },
  {
    path: "/news",
    name: "news",

    component: () => import("@/views/news.vue"),
  },
  {
    path: "/newDetail",
    name: "newDetail",

    component: () => import("@/views/newDetail.vue"),
  },

  {
    path: "/introduce",
    name: "introduce",
    redirect: "/introduce/tzsb",
    component: () => import("@/views/introduce/index.vue"),
    children: [
      {
        path: "applyFORtrial",
        name: "applyFORtrial",

        component: () => import("@/views/introduce/applyFORtrial.vue"),
      },
      {
        path: "tzsb",
        name: "tzsb",

        component: () => import("@/views/introduce/tzsb.vue"),
      },
      {
        path: "tzzy",
        name: "tzzy",

        component: () => import("@/views/introduce/tzzy.vue"),
      },
      {
        path: "ybgm",
        name: "ybgm",

        component: () => import("@/views/introduce/ybgm.vue"),
      },
      {
        path: "jtys",
        name: "jtys",

        component: () => import("@/views/introduce/jtys.vue"),
      },
      {
        path: "zyws",
        name: "zyws",

        component: () => import("@/views/introduce/zyws.vue"),
      },
      {
        path: "qyaq",
        name: "qyaq",

        component: () => import("@/views/introduce/qyaq.vue"),
      },
      {
        path: "qyaq2",
        name: "qyaq2",

        component: () => import("@/views/introduce/qyaq2.vue"),
      },
      {
        path: "qyaq3",
        name: "qyaq3",

        component: () => import("@/views/introduce/qyaq3.vue"),
      },
      {
        path: "qyaq4",
        name: "qyaq4",

        component: () => import("@/views/introduce/qyaq4.vue"),
      },
      {
        path: "jzl",
        name: "jzl",

        component: () => import("@/views/introduce/jzl.vue"),
      },
      {
        path: "zyjn",
        name: "zyjn",

        component: () => import("@/views/introduce/zyjn.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
