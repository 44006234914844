import router from "./router";

//PC端
router.beforeEach(async (to, from, next) => {
  document.body.scrollTop = 0;
  // 兼容firefox
  document.documentElement.scrollTop = 0;
  // 兼容safari
  window.pageYOffset = 0;
  next();
});
