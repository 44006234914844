import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ElementUI from "element-ui";
import "./style/icon/iconfont.js";
import "./style/icon_produce/iconfont.js";
import BaiduMap from "vue-baidu-map";
import "./integrate";
//css
import "@/style/index.scss";
import "element-ui/lib/theme-chalk/index.css";

Vue.use(BaiduMap, { ak: "K3LdsIq339i0G6zuD2wE6iGdWYY1FLUm" });
Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
